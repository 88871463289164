<template>
  <div
    class="lesson-item flex-container"
    :style="{ order: component.order }"
    v-on:keydown.enter="updateLessonName"
  >
    <span>{{ lessonNumber }}</span>
    <select v-model="lessonType">
      <option
        v-for="lessonType in lessonTypes"
        :key="lessonType.value"
        :value="lessonType.value"
        :disabled="shouldBeDisabled(lessonType.value)"
      >
        {{ lessonType.text }}
      </option>
    </select>
    <input
      type="text"
      v-model="name"
      :placeholder="$t('lessonTitlePlaceHolder')"
    />
    <div class="inner-addon right-addon">
      <template v-if="nameChanged">
        <img :src="cancelIcon" @click="undoNameChanges" class="cancel-icon" />
        <img :src="checkIcon" @click="updateLessonName" class="check-icon" />
      </template>
    </div>
    <button
      class="btn secondary"
      :class="[!isDisabled ? 'btn-disabled' : '', 'btn secondary']"
      :disabled="!isDisabled"
      @click="editLesson"
    >
      {{ $t("edit") }}
    </button>
    <img :src="deleteIcon" class="delete-icon" @click="showModal = true" />
    <modal
      v-if="showModal"
      :click-handler="removeLesson"
      :text-button="$t('delete')"
      @close="showModal = false"
    >
      <div class="delete-message row">
        <span>{{ $t("deleteContentMessage") }}</span>
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from "@/common/Modal.vue";
import BackOfficeComponentListItemMixin from "@/components/BackOffice/Mixins/BackOfficeComponentListItemMixin.js";
export default {
  name: "LessonBuilderItem",
  mixins: [BackOfficeComponentListItemMixin],
  props: ["component", "lessType", "itemIndex", "lessons", "unitComponent"],
  components: {
    Modal,
  }, 
  data() {
    return {
      showModal: false,
      lessonType: this.component.lessonTypeId,
      isEditable: false,
    };
  },
  computed: {
    isOverviewItem() {
      return this.lessonType == this.$config.soaConfig.lessonTypes.overview.id
        ? true
        : false;
    },
    isLessonItem() {
      return this.lessonType == this.$config.soaConfig.lessonTypes.lesson.id
        ? true
        : false;
    },
    isAssessmentItem() {
      return this.lessonType == this.$config.soaConfig.lessonTypes.midAssessment.id
        ? true
        : false;
    },
    isSkillItem() {
      return this.lessonType == this.$config.soaConfig.lessonTypes.skill.id
        ? true
        : false;
    },
    isStoryItem() {
      return this.lessonType == this.$config.soaConfig.lessonTypes.story.id
        ? true
        : false;
    },
    lessonTypes() {
      return this.$store.getters.configurationSettings;
    },
    lessonNumber() {
      return this.component.lessonTypeId ==
        this.$config.soaConfig.lessonTypes.lesson.id
        ? this.component.number
        : "";
    },
    lessonName() {
      return this.component.lessonTypeId ==
        this.$config.soaConfig.lessonTypes.activity.id
        ? "Activity"
        : this.component.lessonTypeId ==
          this.$config.soaConfig.lessonTypes.skill.id
        ? "Skill"
        : "";
    },
    isDisabled() {
      return (
        this.component.name[this.locale] != null &&
        this.component.name[this.locale] != "" &&
        this.isEditable
      );
    },
    isHavingOverview() {
      let itemOverview = this.lessons.find(
        (e) => e.lessonTypeId === this.$config.soaConfig.lessonTypes.overview.id
      );
      return itemOverview ? true : false;
    },
    isHavingThreeLessonStory() {
      let lessonStories = this.lessons.filter(
        (e) => e.lessonTypeId === this.$config.soaConfig.lessonTypes.story.id
      );
      return lessonStories.length >= 3;
    },
    isHavingUnitOpener(){
     return  this.unitComponent.unitOpener ? true : false
    }
  },
  watch: {
    lessonType() {
      // this.lessType = val;
      this.updateLessonName();
    },
  },
  methods: {
    removeLesson() {
      this.showModal = false;
      this.$store.dispatch("removeLesson", this.component);
      this.updatingLesson();
    },
    updateLessonName() {
      // this.component.lessonTypeId = this.lessonType;
       let localComponentData = {...this.component}
        localComponentData.lessonTypeId = this.lessonType
      this.$store.dispatch("updateLessonName", localComponentData).then(() => {
        this.orignalName[this.locale] = this.name;
        this.isEditable = true;
      });
      //To show number properly when changing lesson type from non lesson to lesson type
      let itemToUpdate = this.lessons.find((e) => e.id == this.component.id);
      let lessonCurrentNumber = itemToUpdate.order - 1;
      const index = this.lessons.indexOf(itemToUpdate);
      if (index > -1) {
        this.$store.commit("updateLessonNumber",{
           updateObj: index,
           lessonType: this.lessonType
        })
        // this.lessons[index].lessonTypeId = this.lessonType;

        if (this.lessonType === this.$config.soaConfig.lessonTypes.lesson.id)
        this.$store.commit("updateIfSameLessonNumber", {
            updateObj: index,
           lessonCurrentNumber: lessonCurrentNumber
        })
          // this.lessons[index].number = lessonCurrentNumber;
      }
      this.updatingLesson();
    },
    editLesson() {
      let route = null;
      if (this.isOverviewItem) {
        route = {
          // name: "OverviewBuilder",
          name: "OpenerBuilder",
          params: {
            unitId: this.unitId,
          },
        };
        if(!this.isHavingUnitOpener){
           // to create unit opener call this api
         this.$store.dispatch('createUnitOpener', this.unitId)
        } 
       
      } else if (this.isLessonItem) {
        route = {
          name: "LessonBuilder",
          params: {
            unitId: this.unitId,
            lessonId: this.component.id,
          },
        };
      } else if (this.isStoryItem) {
        route = {
          name: "LessonStoryBuilder",
          params: {
            unitId: this.unitId,
            lessonId: this.component.id,
          },
        };
      } else if (this.isAssessmentItem) {
        if (this.component.hasAssessment) {
          route = {
            name: "AssessmentBuilder",
            params: {
              unitId: this.unitId,
              lessonId: this.component.id,
            },
            query: {
              lessonNumber: this.component.number,
              lessonName: this.lessonName,
            },
          };
        } else {
          this.$store
            .dispatch("addAssessmentToLesson", this.component.id)
            .then(() => {
              this.setComponentPropertyValue("hasAssessment", true);
              this.editActivityOrSkill();
            })
            .catch(() => {
              this.$store.commit("addSnackBarNotification", {
                message: this.$t("failedToEditAssessmentAlert"),
              });
            });
        }
      } else if (this.isSkillItem) {
        route = {
          name: "SkillLessonBuilder",
          params: {
            unitId: this.unitId,
            lessonId: this.component.id,
          },
        };
      }
    
      this.$router.push(route);
    },
    setComponentPropertyValue(propertyName, value) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component,
        propertyName: propertyName,
        value: value,
      });
    },
    updatingLesson(payload = {}) {
      if (Object.keys(payload).length === 0 && payload.constructor === Object) {
        payload = {
          component: this.unitComponent,
          lessons: this.lessons,
        };
      }
      this.$store.commit("updateLessonsList", payload);
    },
    shouldBeDisabled(optionValue) {
      return (optionValue == this.$config.soaConfig.lessonTypes.story.id && this.isHavingThreeLessonStory) 
      || (optionValue == this.$config.soaConfig.lessonTypes.overview.id && this.isHavingOverview) ? true : false;
    },
  },
  created() {
    this.isEditable =
      this.component.name[this.locale] != null &&
      this.component.name[this.locale] != "";
  },
  mounted() {
    this.$nextTick(function () {
      this.updatingLesson();
    });
  },
};
</script>
<style lang="less" scoped>
.lesson-item {
  position: relative;
  min-height: 56px;
  margin-top: 8px;
  width: 100%;
  align-content: center;
  margin-right: 10px;
  > span {
    color: #0a1554;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 24px;
    text-transform: uppercase;
    margin-right: 12px;
    position: absolute;
    z-index: 100;
    margin-left: -12px;
  }
  span,
  input,
  img {
    align-self: center;
  }
  > span:first-child {
    height: 24px;
    width: 10px;
    width: 22px;
    margin-right: 18px;
  }
  input:nth-child(2),
  select {
    width: 195px;
    border: 1px solid #cfcfcf;
    background-color: #ffffff;
    height: 40px;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 60px;
    text-overflow: ellipsis;
    padding-bottom: 12px;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    box-sizing: border-box;
    color: #000;
    font-family: "Roboto", sans-serif;
    margin-right: 10px;
    margin-top: 7px;
    margin-left: 10px;
  }
  img {
    margin-left: 21px;
    cursor: pointer;
  }
  .inner-addon {
    position: relative;
  }
  input {
    padding-right: 56px;
    color: #000;
    font-family: Roboto, sans-serif;
  }

  .inner-addon .cancel-icon {
    right: 30px;
    margin-top: 20px;
    // padding-right: 4px;
    width: 16px;
    height: 16px;
    position: absolute;
    // right: 168px;
  }

  .right-addon .check-icon {
    right: 0;
  }
  .inner-addon .check-icon {
    margin-top: 20px;
    // padding-top: 20px;
    right: 4px;
    width: 22px;
    height: 18px;
    position: absolute;
    // right: 136px;
  }
}

.btn {
  align-self: center;
  height: 32px;
  min-width: 65px;
  margin-left: 16px;
}

.btn-disabled {
  color: #ccc;
}
</style>
