var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "lesson-item flex-container",
      style: { order: _vm.component.order },
      on: {
        keydown: function($event) {
          if (
            !("button" in $event) &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.updateLessonName($event)
        }
      }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.lessonNumber))]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.lessonType,
              expression: "lessonType"
            }
          ],
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.lessonType = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.lessonTypes, function(lessonType) {
          return _c(
            "option",
            {
              key: lessonType.value,
              attrs: { disabled: _vm.shouldBeDisabled(lessonType.value) },
              domProps: { value: lessonType.value }
            },
            [_vm._v("\n      " + _vm._s(lessonType.text) + "\n    ")]
          )
        })
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name"
          }
        ],
        attrs: { type: "text", placeholder: _vm.$t("lessonTitlePlaceHolder") },
        domProps: { value: _vm.name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.name = $event.target.value
          }
        }
      }),
      _c(
        "div",
        { staticClass: "inner-addon right-addon" },
        [
          _vm.nameChanged
            ? [
                _c("img", {
                  staticClass: "cancel-icon",
                  attrs: { src: _vm.cancelIcon },
                  on: { click: _vm.undoNameChanges }
                }),
                _c("img", {
                  staticClass: "check-icon",
                  attrs: { src: _vm.checkIcon },
                  on: { click: _vm.updateLessonName }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "button",
        {
          staticClass: "btn secondary",
          class: [!_vm.isDisabled ? "btn-disabled" : "", "btn secondary"],
          attrs: { disabled: !_vm.isDisabled },
          on: { click: _vm.editLesson }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("edit")) + "\n  ")]
      ),
      _c("img", {
        staticClass: "delete-icon",
        attrs: { src: _vm.deleteIcon },
        on: {
          click: function($event) {
            _vm.showModal = true
          }
        }
      }),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.removeLesson,
                "text-button": _vm.$t("delete")
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("div", { staticClass: "delete-message row" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("deleteContentMessage")))])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }