var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid " }, [
    _vm.options
      ? _c(
          "div",
          { staticClass: "list-item row" },
          [
            _vm._t("default"),
            _c(
              "button",
              {
                staticClass: "btn secondary",
                style: {
                  "margin-right": _vm.options.showDeleteIcon ? "0" : "40px"
                },
                on: { click: _vm.editContent }
              },
              [_vm._v(_vm._s(_vm.$t("edit")))]
            ),
            _vm.options.showDeleteIcon
              ? _c("img", {
                  staticClass: "delete-icon",
                  attrs: { src: _vm.deleteIcon },
                  on: {
                    click: function($event) {
                      _vm.showModal = true
                    }
                  }
                })
              : _vm._e(),
            _vm.showModal
              ? _c(
                  "modal",
                  {
                    attrs: {
                      "click-handler": _vm.removeContent,
                      "text-button": _vm.$t("delete")
                    },
                    on: {
                      close: function($event) {
                        _vm.showModal = false
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "delete-message row" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("deleteConfirmationMessage", {
                              "0": _vm.$t(_vm.options.contentType)
                            })
                          )
                        )
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }