<template>
    <div class="unit-builder builder flex-container">
         <div class="container">
            <content-section/>
        </div>
        <properties-panel :save-component="saveComponent"/>
    </div>
</template>
<script>
import UnitBuilderContentSection from '@/components/BackOffice/UnitBuilder/UnitBuilderContentSection.vue'
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'
export default {
    name: 'UnitBuilder',
    mixins:[BuilderMixin], 
    components:{
        'content-section': UnitBuilderContentSection, 
    },
    created(){
        this.$store.commit('updateSwitchButtonDisplayStatus', false)
        this.$store.commit('updateContentSectionState', true)
    }, 
    methods:{
        saveComponent(){
            this.$store.dispatch('updateUnit', this.$store.state.unitBuilderComponent)
        }
    }
}
</script>
<style lang="less" scoped>
.unit-builder{
    margin-top: 104px;
    max-height: calc(100vh - 104px);

    >.container{
        width: 720px;
        max-height: calc(100vh - 104px);
        overflow-y: auto;
        padding: 24px;
        &::-webkit-scrollbar{
            display: none;
        }
    }
}
</style>



