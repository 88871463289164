var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-content-box" },
    [
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.newContentHandler,
                "text-button": _vm.$t("create")
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("div", { staticClass: "content-dialog" }, [
                _c("span", { staticClass: "modal-header" }, [
                  _vm._v(_vm._s(_vm.$t("addContentTag")))
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("typeOfContentTag")) + ":")
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedContent,
                          expression: "selectedContent"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedContent = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.contentOptions, function(option) {
                      return _c(
                        "option",
                        { key: option.text, domProps: { value: option.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(option.text) +
                              "\n          "
                          )
                        ]
                      )
                    })
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _vm.showLessonOptions
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("lesson")) + ":")])
                    : _vm._e(),
                  _vm.showLessonOptions
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedLesson,
                              expression: "selectedLesson"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedLesson = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.lessonOptions, function(lesson) {
                          return _c(
                            "option",
                            {
                              key: lesson.id,
                              attrs: { disabled: lesson.id == null },
                              domProps: { value: lesson }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    lesson.number ? _vm.$t("lesson") : ""
                                  ) +
                                  "\n            " +
                                  _vm._s(
                                    lesson.number
                                      ? lesson.number
                                      : "No lesson found"
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        })
                      )
                    : _vm._e()
                ])
              ])
            ]
          )
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "btn secondary",
          on: {
            click: function($event) {
              _vm.showModal = true
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("addContentTag")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }