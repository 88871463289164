export default {
    props:{
        component:Object
    },  
    data: function(){
        return{
            orignalName:{
                en: '',
                es: ''
            }, 
        }
    },
    computed:{
        deleteIcon(){
            return require('@/assets/icons/ic_delete.svg')
        },
        cancelIcon(){
            return require('@/assets/icons/ic_close.svg')
        },
        checkIcon(){
            return require('@/assets/icons/ic_check_blue.svg')
        },
        name:{
            get(){
                return this.component && this.component.name 
                    ? this.component.name[this.locale]
                    : this.component.title[this.locale]
            }, 
            set(value){
                let propName = this.component && this.component.name ? 'name' : 'title';
                this.$store.commit('setBuilderComponentPropertyValue',
                    {component: this.component, propertyName: propName, value: value, language: this.locale})
            }
        }, 
        unitId(){
            if(this.$route.name.includes('UnitDashboardBuilder'))
                return this.$store.state.currentBackOfficeComponent.id
            return this.$store.state.unitBuilderComponent.id
        },
        nameChanged(){
            return this.name != this.orignalName[this.locale]
        }
    }, 
    methods:{
        undoNameChanges(){
            this.name = this.orignalName[this.locale]
        },
    }, 
    mounted(){
        this.orignalName.en = this.component && this.component.name ? this.component.name.en || '' : this.component.title.en || ''
        this.orignalName.es = this.component && this.component.name ? this.component.name.es || '' : this.component.title.es || ''
    }, 
}