<template>
    <div class="container-fluid ">
            <div class="list-item row" v-if="options">
                <slot></slot>

                <button class="btn secondary" @click="editContent"
                    :style="{'margin-right': options.showDeleteIcon ? '0': '40px'}"
                >{{$t('edit')}}</button>

                <img v-if="options.showDeleteIcon"  :src="deleteIcon" class="delete-icon" @click="showModal = true"/>

                <modal v-if="showModal" :click-handler="removeContent"
                    :text-button="$t('delete')"  @close="showModal = false">
                    <div class="delete-message row">
                        <span>{{$t('deleteConfirmationMessage',{'0': $t(options.contentType)})}}</span>
                    </div>
                </modal>
            </div>
    </div>
</template>
<script>
import Modal from '@/common/Modal.vue'
export default {
    name: 'UnitBuilderListItem',
    components:{
        Modal
    },
    props:{
        options: Object,
        content: {
            type: Object,
            require: false
        }
    },
    data: function(){
        return{
            showModal: false
        }
    },
    methods:{
        removeContent(){
            this.$showModal = false
            this.$emit('onDelete', this.content)
        }, 
        editContent(){
            this.$emit('onEdit', this.content)
        }
    }
}
</script>
<style lang="less" scoped>
.list-item{
    // width: 100%;
    height: 40px;
    cursor: pointer;
    > span{
        text-transform: capitalize;
        align-self: center;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto';
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
    }
    span:nth-child(1){
        margin-left: 8px;
        color: #000 !important;
    }
    span:nth-child(2){
        margin-left: 40px;
    }
    .btn{
        margin-left: auto;
        max-height: 32px;
        max-width: 65px;
        min-width: 65px;
    }
    .delete-icon{
        margin-right: 6px;
        margin-left: 21px;
    }
}
</style>


