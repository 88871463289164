<template>
  <div class="new-content-box">
    <modal
      v-if="showModal"
      :click-handler="newContentHandler"
      :text-button="$t('create')"
      @close="showModal = false"
    >
      <div class="content-dialog">
        <span class="modal-header">{{ $t("addContentTag") }}</span>
        <div class="row">
          <span>{{ $t("typeOfContentTag") }}:</span>
          <select v-model="selectedContent">
            <option
              v-for="option in contentOptions"
              :key="option.text"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="row">
          <span v-if="showLessonOptions">{{ $t("lesson") }}:</span>
          <select v-model="selectedLesson" v-if="showLessonOptions">
            <option
              v-for="lesson in lessonOptions"
              :key="lesson.id"
              :value="lesson"
              :disabled="lesson.id == null"
            >
              {{ lesson.number ? $t("lesson") : "" }}
              {{ lesson.number ? lesson.number : "No lesson found" }}
            </option>
          </select>
        </div>
      </div>
    </modal>
    <button class="btn secondary" @click="showModal = true">
      {{ $t("addContentTag") }}
    </button>
  </div>
</template>
<script>
import Modal from "@/common/Modal.vue";
export default {
  name: "NewContentBox",
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      selectedContent: "",
      selectedLesson: "",
      contentOptions: [
        {
          text: this.$t("biography"),
          value: { handler: "createBiography", showLesson: false },
        },
        {
          text: this.$t("assessment"),
          value: { handler: "addAssessment", showLesson: true },
        },
        {
          text: this.$t("timeline"),
          value: { handler: "goToTimeLineBuilder", showLesson: false },
        },
        {
          text: this.$t("unitStory"),
          value: { handler: "createStory", showLesson: false },
        },
      ],
    };
  },
  computed: {
    lessons() {
      //   let overview = this.$t("overview").toUpperCase();
      //   let result = this.component.lessons;
      //   return this.selectedContent.handler != "addAssessment"
      //     ? result.filter((e) => e.name[this.locale].toUpperCase() != overview)
      //     : result.filter(
      //         (e) =>
      //           e.name[this.locale].toUpperCase() != overview && !e.hasAssessment
      //       );

      //   Added by Animesh
      let result = this.component.lessons;
      let lesson = this.$config.soaConfig.lessonTypes.lesson.id;
      let filteredLessons =
        this.selectedContent.handler != "addAssessment"
          ? result.filter((e) => e.lessonTypeId == lesson)
          : result.filter((e) => e.lessonTypeId == lesson && !e.hasAssessment);
      return filteredLessons;
    },
    showLessonOptions() {
      return this.selectedContent ? this.selectedContent.showLesson : false;
    },
    component() {
      return this.$store.state.unitBuilderComponent;
    },
    lessonOptions() {
      let options;
      if (this.lessons.length > 0) {
        options = this.lessons;
      } else {
        options = [
          {
            id: null,
            number: null,
          },
        ];
      }
      return options;
    },
  },
  methods: {
    createBiography() {
      this.$store.dispatch("createBiography", this.component.id);
    },
    createStory() {
      this.$store.dispatch("createStory", this.component.id);
    },
    newContentHandler() {
      this.showModal = false;
      this[this.selectedContent.handler]();
    },
    goToTimeLineBuilder() {
      let timelineRoute = {
        name: "TimeLineBuilder",
        params: {
          unitId: this.component.id,
        },
      };
      if (this.component.hasTimeLine) {
        this.$router.push(timelineRoute);
        return;
      }
      this.$store
        .dispatch("createTimeLine", this.component.id)
        .then(() => {
          this.setComponentPropertyValue("hasTimeLine", true);
          this.$router.push(timelineRoute);
        })
        .catch(() => {
          this.$store.commit("addSnackBarNotification", {
            message: "Failed to add time line.",
          });
          return;
        });
    },
    addAssessment() {
      if (!this.selectedLesson) {
        this.$store.commit("addSnackBarNotification", {
          message: this.$t("selectLessonAlert"),
        });
        return;
      }
      this.$store
        .dispatch("addAssessmentToLesson", this.selectedLesson.id)
        .then(() => {
          this.setComponentPropertyValue("hasAssessment", true);
          this.$router.push({
            name: "AssessmentBuilder",
            params: {
              lessonId: this.selectedLesson.id,
              unitId: this.component.id,
            },
          });
        })
        .catch(() => {
          this.$store.commit("addSnackBarNotification", {
            message: this.$t("failedToAddAssessmentAlert"),
          });
        });
    },
    setComponentPropertyValue(propertyName, value) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component,
        propertyName: propertyName,
        value: value,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.new-content-box {
  margin-top: 16px;
}
.content-dialog {
  text-transform: capitalize;
  margin: 8px 16px;
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.6);
  width: 328px;
  .modal-header {
    height: 28px;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 28px;
  }
  span,
  select {
    height: 28px;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 28px;
    align-self: center;
    text-transform: capitalize;
  }
  select {
    height: 28px;
    // border: none;
    margin-left: auto;
  }
  .row {
    height: 48px;
    width: 328px;
    span {
      height: 20px;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.25px;
      line-height: 20px;
      min-width: 105px;
    }
    select {
      max-width: 180px;
      min-width: 180px;
    }
  }

  .row:first-child {
    margin-top: 18px;
  }
}
</style>
