var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "biography-item ",
      on: {
        keydown: function($event) {
          if (
            !("button" in $event) &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.updateBiographyName($event)
        }
      }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.$t("Biography")))]),
      _c(
        "div",
        { staticClass: "flex-container bio-container" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            staticStyle: { width: "auto" },
            attrs: {
              type: "text",
              placeholder: _vm.$t("biographyNamePlaceHolder")
            },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          }),
          _vm.nameChanged
            ? [
                _c("img", {
                  staticClass: "cancel-icon",
                  attrs: { src: _vm.cancelIcon },
                  on: { click: _vm.undoNameChanges }
                }),
                _c("img", {
                  staticClass: "check-icon",
                  attrs: { src: _vm.checkIcon },
                  on: { click: _vm.updateBiographyName }
                })
              ]
            : _vm._e(),
          _c(
            "button",
            { staticClass: "btn secondary", on: { click: _vm.editBiography } },
            [_vm._v(_vm._s(_vm.$t("edit")))]
          ),
          _c("img", {
            staticClass: "delete-icon",
            attrs: { src: _vm.deleteIcon },
            on: {
              click: function($event) {
                _vm.showModal = true
              }
            }
          }),
          _vm.showModal
            ? _c(
                "modal",
                {
                  attrs: {
                    "click-handler": _vm.removeBiography,
                    "text-button": _vm.$t("delete")
                  },
                  on: {
                    close: function($event) {
                      _vm.showModal = false
                    }
                  }
                },
                [
                  _c("div", { staticClass: "delete-message row" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("deleteConfirmationMessage", {
                            "0": _vm.$t("biography")
                          })
                        )
                      )
                    ])
                  ])
                ]
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }