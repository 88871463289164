<template>
  <div class="content-section row custom-scrollbar" v-if="component">
    <section class="column">
      <div class="image-placeholder flex-container">
        <image-upload :component="component" :options="imageUploadProperties" />
      </div>
      <span class="legends" v-show="false">{{ $t("unitDescription").toUpperCase() }}</span>
      <div class="text-placeholder" v-show="false">
        <textarea
          name="text"
          :placeholder="$t('unitDescription') + '...'"
          class="custom-scrollbar"
          v-model="description"
          rows="5"
          cols="30"
        ></textarea>
      </div>
    </section>
    <section class="column">
      <span class="legend">{{ $t("unitTitleTag") }}</span>
      <div class="container-fluid">
        <div class="title-section row">
          <input
            type="text"
            class="title-placeholder"
            v-model="name"
            :placeholder="$t('titlePlaceHolder')"
          />
          <input
            type="text"
            class="title-placeholder"
            v-model="period"
            :placeholder="$t('unitPeriodPlaceHolder')"
          />
        </div>
      </div>
      <span class="legends">{{ $t("lessons") }}</span>
      <div class="lessons-list flex-container" v-if="lessons">
        <lesson-item
          v-for="(lesson, index) of lessons"
          :key="`lesson: ${lesson.id} ${index}`"
          :component="lesson"
          :lessType="lesson.lessonTypeId"
          :itemIndex="index"
          :lessons="lessons"
          :unitComponent="component"
        />
      </div>
      <div class="new-lesson-section flex-container">
        <!-- <span class="legend">{{ lessons.length || 1 }}</span> -->
        <button class="btn secondary" @click="createLesson">
          {{ $t("addLessonTag") }}
        </button>
      </div>
      <span class="other-content">{{ $t("otherContentTag") }}</span>
      <biography-item
        v-for="biography of biographies"
        :key="biography.id"
        :component="biography"
      />
      <story-item
        v-for="story of unitStories"
        :key="story.id"
        :component="story"
      />

      <list-item
        v-for="lesson of lessonsWithAssessment"
        :content="lesson"
        @onEdit="editAssessment"
        @onDelete="removeAssessment"
        :key="'assessment:' + lesson.id"
        :options="assessmentItemsOptions"
      >
        <span
          >{{ $t("assessment") }} - {{ $t("lesson") }} {{ lesson.number }}</span
        >
      </list-item>
      <list-item
        v-if="component.hasTimeLine"
        :options="timelineItemOptions"
        @onEdit="editTimeLine"
        @onDelete="removeTimeLine"
      >
        <span>{{ $t("timeline") }} </span>
      </list-item>

      <list-item
        :content="component"
        @onEdit="goToTeacherPage"
        :key="'teacherPage unit:' + component.id"
        :options="teacherPageItemOptions"
      >
        <span
          >{{ $t("teacherPage") }} - {{ $t("unit") }}
          {{ component.number }}</span
        >
      </list-item>

      <list-item
        :class="lesson.number==0 ? 'd-none':''"
        v-for="lesson of teacherPageLessons"
        :content="lesson"
        @onEdit="goToTeacherPage"
        :key="'teacherPage:' + lesson.id"
        :options="teacherPageItemOptions"
      >
        <span
          >{{ $t("teacherPage") }} - {{ $t("lesson") }}
          {{ lesson.number }}</span
        >
      </list-item>

      <list-item :options="vocabularyItemOptions" @onEdit="editVocabulary">
        <span>{{ $t("vocabulary") }} </span>
      </list-item>

      <new-content-box />
    </section>
  </div>
</template>
<script>
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
import LessonBuilderItem from "@/components/BackOffice/UnitBuilder/LessonBuilderItem.vue";
import BiographyComponentItem from "@/components/BackOffice/UnitBuilder/BiographyComponentItem.vue";
import StoryComponentItem from "@/components/BackOffice/UnitBuilder/StoryComponentItem.vue";
import UnitBuilderListItem from "@/components/BackOffice/UnitBuilder/UnitBuilderListItem.vue";

import NewContentBox from "./NewContentBox.vue";
export default {
  name: "UnitContentSection",
  components: {
    "image-upload": ImageUpload,
    "lesson-item": LessonBuilderItem,
    "biography-item": BiographyComponentItem,
    "story-item": StoryComponentItem,
    "new-content-box": NewContentBox,
    "list-item": UnitBuilderListItem,
  },
  data: function () {
    return {
      nextLessonNumber: "",
      assessmentItemsOptions: {
        contentType: "assessment",
        showDeleteIcon: true,
      },
      timelineItemOptions: {
        contentType: "timeline",
        showDeleteIcon: true,
      },
      teacherPageItemOptions: {
        showDeleteIcon: false,
      },
      vocabularyItemOptions: {
        showDeleteIcon: false,
      },
    };
  },
  computed: {
    name: {
      get() {
        return this.component.name[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue("name", value, this.locale);
      },
    },
    period: {
      get() {
        return this.component.period;
      },
      set(value) {
        this.setComponentPropertyValue("period", value);
      },
    },
    description: {
      get() {
        return this.component.description[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue("description", value, this.locale);
      },
    },
    infoIcon() {
      return require("@/assets/icons/ic_info.svg");
    },
    component() {
      return this.$store.state.unitBuilderComponent;
    },
    lessons() {
      return this.component.lessons;
    },
    biographies() {
      return this.component.biographies;
    },
    unitStories() {
      return this.component.unitStories;
    },
    lessonsWithAssessment() {
      return this.component.lessons.filter((e) => e.hasAssessment);
    },
    teacherPageLessons() {
      return this.lessons.filter((e, index) => index > 0);
    },
    imageUploadProperties() {
      return { name: "image-upload", text: "addImageText", model: "imageId" };
    },
  },
  methods: {
    setComponentPropertyValue(propertyName, value, language = null) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
    createLesson() {
      this.$store.dispatch("createLesson", this.component.id);
    },
    removeUnitImage() {
      this.setComponentPropertyValue("", "imageId");
    },
    editTimeLine() {
      this.$router.push({
        name: "TimeLineBuilder",
        params: {
          unitId: this.component.id,
        },
      });
    },
    removeTimeLine() {
      this.$store.dispatch("removeTimeLine", this.component.id).then(() => {
        this.setComponentPropertyValue("hasTimeLine", false);
      });
    },
    goToTeacherPage(content) {
      this.$router.push({
        name: "TeacherPageBuilder",
        params: {
          teacherPageId: content.skillsId,
          ...this.$route.params,
        },
        query: {
          title: content.name[this.locale],
          lessonNumber: content != this.component ? content.number : 0,
        },
      });
    },
    editVocabulary() {
      this.$router.push({
        name: "VocabularyManager",
        params: {
          unitId: this.component.id,
        },
      });
    },
    removeAssessment(lesson) {
      this.$store.dispatch("removeAssessment", lesson.id).then(() => {
        this.$store.commit("setBuilderComponentPropertyValue", {
          component: lesson,
          propertyName: "hasAssessment",
          value: false,
        });
      });
    },
    lessonName(lesson) {
      let lessonName =
        lesson.lessonTypeId == this.$config.soaConfig.lessonTypes.activity.id
          ? "Activity"
          : lesson.lessonTypeId == this.$config.soaConfig.lessonTypes.skill.id
          ? "Skill"
          : "";
      return lessonName;
    },
    editAssessment(lesson) {
      this.$router.push({
        name: "AssessmentBuilder",
        params: {
          lessonId: lesson.id,
          unitId: this.$route.params.unitId,
        },
        query: {
          lessonNumber: lesson.number,
          lessonName: this.lessonName(lesson),
        },
      });
    },
  },
  created() {
    this.$store.commit("updateContentSectionState", true);
    this.$store
      .dispatch("loadUnitBuilderComponent", this.$route.params.unitId)
      .then((unitBuilderComponent) => {
        this.$store.commit("loadUnitBuilderComponent", unitBuilderComponent);
        this.$store.commit(
          "updateCurrentBackOfficeComponent",
          unitBuilderComponent
        );
      });
    this.$store.dispatch("configurationSettings", {
      lessonType: "LESSONTYPE",
      language: this.locale,
    });
  },
  beforeDestroy() {
    this.$store.commit("updateCurrentBackOfficeComponent", undefined);
  },
};
</script>
<style lang="less" scoped>
.legends {
  font-size: 17px;
  font-weight: bold;
  min-height: 24px;
  width: auto;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  color: #0a1554;
  text-transform: uppercase;
}
.unit-builder {
  .content-section {
    padding-bottom: 24px;
    height: auto;
    width: 704px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: var(--secondary-shadow);
  }
  .image-placeholder {
    height: 144px;
    width: 182px;
    margin-left: 0;
    background-color: #efefef;
    :global(.image-upload) {
      margin: auto;
      width: 100%;
      height: 100%;
      min-width: 100%;
      flex-direction: column;

      :global(.delete-icon) {
        order: 0;
        margin-top: 8px;
        height: 16px;
        max-width: 16px;
        margin-left: auto;
      }

      :global(.image) {
        order: 1;
        height: 64px;
        width: 80px;
        max-height: 64px;
        max-width: 80px;
        margin: 11px auto;
      }
      :global(span) {
        order: 2;
        margin: -3px auto;
        text-align: center;
      }
    }
  }

  .list-item {
    width: 100%;
    height: 40px;
    span {
      text-transform: capitalize;
      align-self: center;
      color: rgba(0, 0, 0, 0.6);
      font-family: "Roboto";
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 28px;
    }
    span:nth-child(1) {
      margin-left: 8px;
    }
    span:nth-child(2) {
      margin-left: 40px;
    }

    .btn {
      margin-left: auto;
      max-height: 32px;
      max-width: 65px;
      min-width: 65px;
    }

    .delete-icon {
      margin-right: 6px;
      margin-left: 21px;
    }

    &:hover {
      // background-color: rgba(0,0,0,0.05);
      cursor: pointer;
    }
  }

  section:first-of-type {
    flex-direction: column;
    width: 182px;
    max-width: 182px;
    margin-left: 24px;
    span:first-of-type {
      margin-top: 16px;
      white-space: normal;
    }
    .text-placeholder {
      width: inherit;
      max-width: inherit;
      margin-top: 8px;
      box-sizing: border-box;
      clear: both;
      height: auto;
      max-height: none;
      textarea {
        padding-top: 16px;
        padding-left: 16px;
        box-sizing: border-box;
        resize: vertical;
        max-height: 100%;
        overflow: auto;
        height: unset;
      }
    }
  }

  section:nth-child(2) {
    flex-direction: column;
    margin-left: 18px;
    margin-top: 26px;
    .title-placeholder {
      margin-top: 16px;
      height: 40px;
      width: 275px;
      padding-left: 24px;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      border: 1px solid #cfcfcf;
    }

    .title-placeholder:nth-child(2) {
      height: 40px;
      width: 107px;
      margin-left: 16px;
    }
    span:nth-child(3) {
      margin-top: 20px;
    }
  }

  .lessons-list {
    width: 464px;
    margin-top: 22px;
    min-height: 56px;
    flex-direction: column;
  }
  .new-lesson-section {
    margin-top: 12px;
    button {
      margin-left: 28px;
    }
    margin-bottom: 43px;
    span {
      margin-left: -14px;
    }
  }

  .other-content {
    text-transform: uppercase;
    height: unset;
    font-weight: bold;
    letter-spacing: 2px;
    color: #0a1554;
    font-size: 19.64px;
    letter-spacing: 0.25px;
    line-height: 24px;
  }
}
.d-none{
  display:none
}
</style>
