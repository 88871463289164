var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.component
    ? _c("div", { staticClass: "content-section row custom-scrollbar" }, [
        _c("section", { staticClass: "column" }, [
          _c(
            "div",
            { staticClass: "image-placeholder flex-container" },
            [
              _c("image-upload", {
                attrs: {
                  component: _vm.component,
                  options: _vm.imageUploadProperties
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              staticClass: "legends"
            },
            [_vm._v(_vm._s(_vm.$t("unitDescription").toUpperCase()))]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              staticClass: "text-placeholder"
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.description,
                    expression: "description"
                  }
                ],
                staticClass: "custom-scrollbar",
                attrs: {
                  name: "text",
                  placeholder: _vm.$t("unitDescription") + "...",
                  rows: "5",
                  cols: "30"
                },
                domProps: { value: _vm.description },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.description = $event.target.value
                  }
                }
              })
            ]
          )
        ]),
        _c(
          "section",
          { staticClass: "column" },
          [
            _c("span", { staticClass: "legend" }, [
              _vm._v(_vm._s(_vm.$t("unitTitleTag")))
            ]),
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "title-section row" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name"
                    }
                  ],
                  staticClass: "title-placeholder",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("titlePlaceHolder")
                  },
                  domProps: { value: _vm.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name = $event.target.value
                    }
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.period,
                      expression: "period"
                    }
                  ],
                  staticClass: "title-placeholder",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("unitPeriodPlaceHolder")
                  },
                  domProps: { value: _vm.period },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.period = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("span", { staticClass: "legends" }, [
              _vm._v(_vm._s(_vm.$t("lessons")))
            ]),
            _vm.lessons
              ? _c(
                  "div",
                  { staticClass: "lessons-list flex-container" },
                  _vm._l(_vm.lessons, function(lesson, index) {
                    return _c("lesson-item", {
                      key: "lesson: " + lesson.id + " " + index,
                      attrs: {
                        component: lesson,
                        lessType: lesson.lessonTypeId,
                        itemIndex: index,
                        lessons: _vm.lessons,
                        unitComponent: _vm.component
                      }
                    })
                  })
                )
              : _vm._e(),
            _c("div", { staticClass: "new-lesson-section flex-container" }, [
              _c(
                "button",
                {
                  staticClass: "btn secondary",
                  on: { click: _vm.createLesson }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("addLessonTag")) + "\n      "
                  )
                ]
              )
            ]),
            _c("span", { staticClass: "other-content" }, [
              _vm._v(_vm._s(_vm.$t("otherContentTag")))
            ]),
            _vm._l(_vm.biographies, function(biography) {
              return _c("biography-item", {
                key: biography.id,
                attrs: { component: biography }
              })
            }),
            _vm._l(_vm.unitStories, function(story) {
              return _c("story-item", {
                key: story.id,
                attrs: { component: story }
              })
            }),
            _vm._l(_vm.lessonsWithAssessment, function(lesson) {
              return _c(
                "list-item",
                {
                  key: "assessment:" + lesson.id,
                  attrs: {
                    content: lesson,
                    options: _vm.assessmentItemsOptions
                  },
                  on: {
                    onEdit: _vm.editAssessment,
                    onDelete: _vm.removeAssessment
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("assessment")) +
                        " - " +
                        _vm._s(_vm.$t("lesson")) +
                        " " +
                        _vm._s(lesson.number)
                    )
                  ])
                ]
              )
            }),
            _vm.component.hasTimeLine
              ? _c(
                  "list-item",
                  {
                    attrs: { options: _vm.timelineItemOptions },
                    on: {
                      onEdit: _vm.editTimeLine,
                      onDelete: _vm.removeTimeLine
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("timeline")) + " ")])]
                )
              : _vm._e(),
            _c(
              "list-item",
              {
                key: "teacherPage unit:" + _vm.component.id,
                attrs: {
                  content: _vm.component,
                  options: _vm.teacherPageItemOptions
                },
                on: { onEdit: _vm.goToTeacherPage }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("teacherPage")) +
                      " - " +
                      _vm._s(_vm.$t("unit")) +
                      "\n        " +
                      _vm._s(_vm.component.number)
                  )
                ])
              ]
            ),
            _vm._l(_vm.teacherPageLessons, function(lesson) {
              return _c(
                "list-item",
                {
                  key: "teacherPage:" + lesson.id,
                  class: lesson.number == 0 ? "d-none" : "",
                  attrs: {
                    content: lesson,
                    options: _vm.teacherPageItemOptions
                  },
                  on: { onEdit: _vm.goToTeacherPage }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("teacherPage")) +
                        " - " +
                        _vm._s(_vm.$t("lesson")) +
                        "\n        " +
                        _vm._s(lesson.number)
                    )
                  ])
                ]
              )
            }),
            _c(
              "list-item",
              {
                attrs: { options: _vm.vocabularyItemOptions },
                on: { onEdit: _vm.editVocabulary }
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("vocabulary")) + " ")])]
            ),
            _c("new-content-box")
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }